import { useSelector, useDispatch } from "react-redux";
import { selectTag } from "./noteSlice";

const SideBar = () => {
  const notes = useSelector((state) => state.notes.all);
  const selectedTags = useSelector((state) => state.notes.selectedTags);
  const dispatch = useDispatch();
  const tags = notes.reduce((accumulator, current) => {
    current.tags.forEach((tag) => {
      const doesTagExists = accumulator.some((item) => {
        return item.toLowerCase() === tag.toLowerCase();
      });

      if (!doesTagExists) {
        accumulator = [...accumulator, tag];
      }
    });
    return accumulator;
  }, []);

  return (
    <section className="notesLeft">
      <p>Profile</p>
      <h3>Tags</h3>
      <dl>
        {tags.length > 0
          ? tags.map((tag, index) => (
              <button
                key={index}
                id={tag}
                className={`tags ${
                  selectedTags.some((item) => item === tag) ? "selected" : ""
                }`}
                onClick={() => dispatch(selectTag(tag))}
              >
                {tag}
              </button>
            ))
          : ""}
      </dl>
    </section>
  );
};

export default SideBar;
