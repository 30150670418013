import { Component } from "react";
import { Link } from "react-router-dom";
import * as React from 'react'

import SignUp from "./SignUp";
import PasswordReset from "./PasswordReset";

import signUp from "./css/signUp.css";

class LogIn extends Component {

    state = {
        email : ''
    };
    
    handleNextLogIn = (event) => {
        console.log("ENTRO A handleNextLogIn");
        const { value } = event.target;
        //console.log("THIS.id "+this.id);  //undefined
        console.log("THIS.value "+value); //undefined
        //console.log("THIS.email "+this.email); //vacio

        console.log("THIS.state "+this.state); //undefined

        //<Link to="/password" state={{email: this.email}}></Link>
    };

    handleForgotPassword = () => {
        <PasswordReset/>
    };

    handleSignUp = () => {
        <SignUp/>
    };

    changeEmail(event) {
        const { value } = event.target;
        this.setState({email: value});
    };


    render(){
        const { email } = this.state;

        console.log(`Email: ${email}`);
        return (
            <div>
            <form style={signUp}>
                <div className="container">
                    <h1>Log in to Assistfolk</h1>
                    <p>Please Log In.</p>
                    <hr></hr>
                    <label htmlFor="email"><b>Email</b></label>
                    <input id="email" type="text" name="email" placeholder="Email address" onChange={this.changeEmail.bind(this)} required/>
                    <div className="clearfix">
                        {/* 
                        <Link to="/Password" state={{email: this.email}}></Link>
                        */}                  
                        <Link to="/password" state={{email: email}}>
                            <button 
                                type="nextButton" 
                                className="nextLogInButton"
                                disabled={!email}
                            >Next
                            </button>
                        </Link>
                        <Link to="/passwordReset" state={{email: email}}>
                            <button 
                                type="forgotPasswordButton" 
                                className="forgotPasswordButton"
                            >Forgot password ?
                            </button>
                        </Link>
                    </div>
                    <div>
                        <p>Don&apos;t have an account?
                            {/* <a 
                                href={signUpURL}
                                onClick={this.handleSignUp}>
                             Sign up
                            </a> */}
                            <Link to="/signUp">Sign up</Link>
                        </p>
                        
                        <p hidden>
                            <Link to="/noteContainer">&#32;NoteContainer.js</Link>
                        </p>
                    </div>
                </div>
                
            </form>
            
            </div>
        );
    }
}


export default LogIn;