import { Component } from "react";
import { Link } from 'react-router-dom';

import signUp from "./css/signUp.css";

class SignUp extends Component {

    handleSignUp = () => {
        
    };

    handleCancelSignUp = () => {
        <SignUp />
    }

    render(){

        
        return (
            <form style={signUp}>
                <div className="container">
                    <h1>Sign Up</h1>
                    <p>Please fill in this form to create an account.</p>
                    <hr></hr>
                    <label htmlFor="email"><b>Email</b></label>
                    <input id="email" type="text" name="email" placeholder="Enter Email" required/>
                    
                    <label htmlFor="psw"><b>Password</b></label>
                    <input id="psw" type="password" name="psw" placeholder="Enter Password" required/>
                    
                    <label htmlFor="psw-repeat" id="psw-repeat"><b>Repeat Password</b></label>
                    <input id="psw-repeat" type="password" name="psw-repeat" placeholder="Repeat Password" required/>
                    
                    <div className="clearfix">
                        <Link to="/">
                            <button 
                                type="cancelButton"
                                className="cancelbtn"
                                >Cancel
                            </button>
                        </Link>
                        <button 
                            type="submitButton" 
                            className="signupbtn"
                            onClick={this.handleSignUp}
                            >Sign up
                        </button>
                    </div>

                    <div>
                    <p>Have an account already? 
                        <Link to="/logIn">Log in </Link>
                    </p>
                </div>
                </div> 
            </form>
        );
    }
}


export default SignUp;