import { useLocation, Link } from 'react-router-dom'

import LogIn from "./LogIn";
import SignUp from "./SignUp";


import signUp from "./css/signUp.css";

function Password() {
    const location = useLocation();
    const { email } = location.state;
    console.log(email);

    const handleLogIn = () => {
        <LogIn/>
    };

    const handleCancel = () => {
        
    };

    const handleSignUp = () => {
        <SignUp/>
    };


    return (
        <form style={signUp}>
            <div className="container">
                <h1>Log in to Assistfolk</h1>
                <p>Please enter your password.</p>
                <hr></hr>
                <label htmlFor="email"><b>Email</b></label>
                <input id="email" type="text" name="email" placeholder="Debe traer el Email del usuario" disabled value={email} />
                
                <label htmlFor="psw"><b>Password</b></label>
                <input id="psw" type="password" name="psw" placeholder="Enter Password" required/>

                <Link to="/passwordReset" state={{email: email}}>
                    <p>Forgot password ?</p>
                </Link>
             
                <div className="clearfix">
                    <Link to="/">
                        <button 
                            type="cancelButton"
                            className="cancelbtn"
                            onClick={handleCancel}
                            >Cancel
                        </button>
                    </Link>
                    <button 
                        type="submitLogInButton" 
                        className="signupbtn"
                        onClick={handleLogIn}
                        >Log in
                    </button>
                </div>

                <div>
                <p>Don&apos;t have an account? 
                    <a 
                        href="/"
                        onClick={handleSignUp}>
                        Sign up
                    </a>
                </p>
            </div>
            </div> 
        </form>
    );
}

export default Password;