import { Component } from "react";
import { connect } from "react-redux";

import SideBar from "./SideBar";

import { addNote, updateNote, removeNote } from "./noteSlice";

import addButton from "./imgs/notes/addNote.png";
import saveButton from "./imgs/notes/saveNotes.png";
import './css/notes.css';


class NoteContainer extends Component {
  state = {
    editing: false,
  };

  handleNoteAdd = () => {
    console.log("1. Hizo click en boton agregar Nota, entro a handleNoteAdd");
    console.log("2. Estoy en handleNoteAddValor y el valor de editing es: " + this.state.editing);

    const { addNote, selectedTags } = this.props;
    const note = {
      // eslint-disable-next-line no-restricted-globals
      id: self.crypto.randomUUID(),
      text: selectedTags.length > 0 ? selectedTags.join(' ') : '',
      comments: [],
      tags: selectedTags,
    };
    this.setState({ editing: true });
    addNote(note);
  };

  isEmptyNote() {
    let empty = false;
    const { notes } = this.props;
    notes.forEach((note) => {
      if (!note.text || note.text.trim === "") {
        empty = true;
      }
    });
    return empty;
  }

  handleNoteUpdate = (event) => {
    console.log("3. Entro a crear la nota en handleNoteUpdate");
    console.log("4. Estoy handleNoteUpdate y el valor de Editing es: "+ this.state.editing);

    const { notes, updateNote, removeNote } = this.props;
    const { id, value } = event.target;

    this.setState({
      editing: event.type === "change"
    });

    console.log("5. Despues de editar la nota, el Estado de editing en handleNoteUpdate es: "+this.state.editing);

    let tags = [];
    if (event.type === "blur") {  
      if (!value || value.trim() === '') {
        this.setState({editing: false});
        console.log("6. Cuando event.type es blur, el Estado de editing en handleNoteUpdate es: "+this.state.editing);
        removeNote({id});
        return;
      }

      if (value.includes("#")) {
        tags = value.match(/#[a-z]*[0-9]*/g);    //Hay un bug no toma la mayuscula en los TAGS
      }
    }

    notes.forEach((note) => {
      if (note.id === id)
        updateNote({
          ...note,
          text: value,
          tags: event.type === "blur" ? tags : note.tags,
        });
    });
  };

  handleNoteSave = (event) => {
    console.log("7. Entro a guardar la nota en handleNoteSave ");
    
    //Debo poner inactivo el boton de agregar nota, osea Editing en false, 
    //porque no se ha guardado la nota q se esta creando
    console.log("8. Estoy guargando la nota en handleNoteSave y el estado de Editinsg es: " + this.state.editing);

  }

  render() {
    

    const { editing } = this.state;
    const { notes } = this.props;
    
    return (
      <>
        <SideBar/>
        <div className="container">
          <div className="textAreaButtonDone">
            {
              //eslint-disable-next-line
              <img
                alt="Add Note"
                id="addNote"
                className={editing ? "addNoteDisabled" : "addNote"}
                src={addButton}
                onClick={this.handleNoteAdd}
              />
            }
            {notes.length > 0 ? (
              notes.map((note) => (
                <>
                  <textarea
                    id={note.id}
                    key={note.id}
                    value={note.text}
                    className="note"
                    placeholder="type your note here and #keepRocking"
                    onChange={this.handleNoteUpdate}
                    onBlur={this.handleNoteUpdate}
                  >
                  </textarea>
                  {//eslint-disable-next-line                   
                  <img 
                    alt="Save Note"
                    id={`idNote${note.id}`}
                    key={`SaveNote${note.id}`}
                    className={editing ? "saveNoteButtonDisabled" : "saveNoteButton"}
                    src={saveButton}
                    onClick={this.handleNoteSave}
                  />}
                </>
              ))
            ) : (
              <p>no notes yet!</p>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notes: state.notes.filtered,
    selectedTags: state.notes.selectedTags,
  };
};

const mapDispatchToProps = {
  addNote,
  updateNote,
  removeNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteContainer);
