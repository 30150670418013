import { createSlice } from "@reduxjs/toolkit";

export const noteSlice = createSlice({
  name: "notes",
  initialState: {
    all: [],
    selectedTags: [],
    filtered: [],
  },
  reducers: {
    saveNote: (state, action) =>{
      console.log("entro al Reducer savenote");
    },

    addNote: (state, action) => {
      state.all = [action.payload, ...state.all];
      state.filtered =
        state.selectedTags.length > 0
          ? state.all.filter((note) =>
              state.selectedTags.some((item) =>
                note.tags.some((tag) => tag === item)
              )
            )
          : state.all;
    },
    updateNote: (state, action) => {
      state.all = state.all.map((note) =>
        note.id === action.payload.id ? { ...note, ...action.payload } : note
      );
      state.filtered =
        state.selectedTags.length > 0
          ? state.all.filter((note) =>
              state.selectedTags.some((item) =>
                note.tags.some((tag) => tag === item)
              )
            )
          : state.all;
    },
    removeNote: (state, action) => {
      state.all = state.all.filter((note) => note.id !== action.payload.id);
      state.filtered =
        state.selectedTags.length > 0
          ? state.all.filter((note) =>
              state.selectedTags.some((item) =>
                note.tags.some((tag) => tag === item)
              )
            )
          : state.all;
    },
    selectTag: (state, action) => {
      console.log(action.payload);
      state.selectedTags = state.selectedTags.some(
        (tag) => tag === action.payload
      )
        ? state.selectedTags.filter((tag) => tag !== action.payload)
        : [action.payload, ...state.selectedTags];
      state.filtered =
        state.selectedTags.length > 0
          ? state.all.filter((note) =>
              state.selectedTags.some((item) =>
                note.tags.some((tag) => tag === item)
              )
            )
          : state.all;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addNote, updateNote, removeNote, saveNote, selectTag } = noteSlice.actions;

export default noteSlice.reducer;
