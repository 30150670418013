import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./Header";
import SideBar from "./SideBar";
import NoteContainer from "./NoteContainer";
import LogIn from "./LogIn";
import SignUp from "./SignUp";
import PasswordReset from "./PasswordReset";
import Password from "./Password";
import './css/main.css';


export default function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LogIn />} />
          {/* <Route index element={<Home />} />  */}
          <Route path="sideBar" element={<SideBar />} />
          <Route path="logIn" element={<LogIn />} />
          <Route path="password" element={<Password />} />
          <Route path="signUp" element={<SignUp />} />
          <Route path="passwordReset" element={<PasswordReset />} />
          <Route path="noteContainer" element={<NoteContainer />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
        {/*  <Header />
          
          <LogIn/>,
          <Password/>,
          <SignUp/>,
          <PasswordReset/>
          <NoteContainer /> 
          */}   
      </BrowserRouter>
    </>
  ); 
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider Provider store={store}>
    <App />
  </Provider>,
);  