import headerImg from "./imgs/footer/footer.png";

const Header = () => {
  return (
    <header>
      <div>
        <img src={headerImg} alt="Logo Assistfolk" />
      </div>
    </header>  
  );
};

export default Header;
