import { useLocation, Link } from 'react-router-dom'

import signUp from "./css/signUp.css";

function PasswordReset() {
    const location = useLocation();
    const { email } = location.state;
    console.log(email);

    const handleNextResetPassword = () => {

    };


    return (
        <form style={signUp}>
            <div className="container">
                <h1>Find your Assistfolk account</h1>
                <p>Enter the email associated with your account to change your password.</p>
                <hr></hr>
                <label htmlFor="email"><b>Email</b></label>
                {/* <input id="email" type="text" name="email" placeholder="Email address" required/>  */}
                <input id="email" type="text" name="email" placeholder="Email"/>
                
                <div className="clearfix">
                    <Link to="/">
                        <button 
                            type="cancelButton"
                            className="cancelbtn"
                            >Cancel
                        </button>
                    </Link>
                    <button 
                        type="nextFindAccountButton" 
                        className="signupbtn"
                        onClick={handleNextResetPassword}
                        >Next
                    </button>
                    
                </div>
            </div>
        </form>
    );
    
}

export default PasswordReset;
